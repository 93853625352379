<template>
  <v-list>
    <v-list-item v-if="productData.status !== 2">
      <v-btn color="success" class="my-1" block @click="changeStatus(productData.id,2, productData.market_id)">{{ $t('Verify') }}</v-btn>
    </v-list-item>
    <v-list-item v-if="productData.status !== 4">
      <v-dialog
        v-model="dialogReject"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            color="warning"
            class="my-1"
            v-bind="attrs"
            v-on="on"
            block
          >
            {{ $t('Reject') }}
          </v-btn>
        </template>
        <v-card>
          <v-form ref="formReject" :v-model="validReject">
            <v-card-title class="pb-0">
              <span class="text-h5">{{ $t('Reject Product') }}</span>
            </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-radio-group v-model="rejectInfo">
                          <span v-for="item in rejectVariants.slice(0, 8)" class="m-2">
                            <v-radio
                              :key="item.id"
                              :label="$t(item.title)"
                              :value="$t(item.title)"
                              @click="rejectNote = item.description"
                            />
                          </span>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="6">
                      <v-radio-group v-model="rejectInfo">
                          <span v-for="item in rejectVariants.slice(8)" class="m-2">
                            <v-radio
                              :key="item.id"
                              :label="$t(item.title)"
                              :value="$t(item.title)"
                              @click="rejectNote = item.description"
                            />
                          </span>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-textarea
                      height="150"
                      outlined
                      name="input"
                      label="Description"
                      v-model="rejectNote"
                    />
                  </v-container>
                </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogReject = false"
              >
                {{ $t('Close') }}
              </v-btn>
              <v-btn
                  color="error"
                  text
                  @click="changeStatus(productData.id,3, productData.market_id, rejectInfo + '. ' + rejectNote)"
              >
                {{ $t('Reject') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-list-item>
    <v-list-item v-if="productData.status !== 4">
      <v-dialog
          v-model="dialogBan"
          persistent
          max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="error" class="my-1" v-bind="attrs" v-on="on" block>
            {{ $t('Ban') }}
          </v-btn>
        </template>
        <v-card>
          <v-form ref="formBan" :v-model="validBan">
            <v-card-title>
              <span class="text-h5">{{ $t('Ban Product') }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                        outlined
                        name="input"
                        :rules="noteRules"
                        :label="$t('Ban reason')"
                        v-model="banNote"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogBan = false"
              >
                {{ $t('Close') }}
              </v-btn>
              <v-btn color="error darken-1" text @click="changeStatus(productData.id,4, productData.market_id, banNote)">
                {{ $t('Ban') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-list-item>
  </v-list>
</template>

<script>
import {computed, getCurrentInstance, ref} from "@vue/composition-api";
import store from "@/store";
import axios from "@axios";
import router from "@/router";
import {getRejectVariants} from "@/plugins/helpers";
import {watch} from "@vue/composition-api/dist/vue-composition-api";

export default {
  name: "AdminProductButtons",
  setup() {
    const rejectVariants = computed(() => getRejectVariants())
    const rejectInfo = ref('')
    const vm = getCurrentInstance().proxy
    const dialogReject = ref(false)
    const dialogBan = ref(false)
    const rejectNote = ref('')
    const banNote = ref('')
    const noteRules = [v => !!v || 'Reject note required', v => v.length > 20 || 'Minimal length of characters is 20']
    const validReject = ref(false)
    const validBan = ref(false)
    const formReject = ref(null)
    const formBan = ref(null)
    const productData = computed(() => store.state.product.product)
    watch(() => dialogBan.value, () => banNote.value = '')
    watch(() => dialogReject.value, () => {
      rejectNote.value = ''
      rejectInfo.value = ''
    })

    const changeStatus = (id, status, market_id, note) => {
      if (status == 3) {
        if (formReject.value) {
          formReject.value.validate()
        }
        if ((status == 3 || status == 4) && !validReject) {
          return
        }
      } else if (status == 4) {
        if (formBan.value) {
          formBan.value.validate()
        }
        if ((status == 3 || status == 4) && !validBan) {
          return
        }
      }

      axios.post(`admin/product-approve/${id}`, {status, market_id, note, _method: 'put'})
          .then(res => {
            let statusText
            switch (status) {
              case 2:
                statusText = 'approved';
                break;
              case 3:
                statusText = 'rejected';
                break;
              case 4:
                statusText = 'banned';
                break;
              default:
                statusText = 'published'
            }
            if(router.currentRoute.name == 'admin-product') {
              store.dispatch('product/getAdminProduct', {id: id})
            }else {
              store.dispatch('product/getSellerProduct', {id: id})
            }
            vm.$notify({
              group: 'deals',
              title: vm.$t(`Product ${statusText}`),
              type: 'success'
            })
            dialogReject.value = false
            dialogBan.value = false
          })
          .catch()
    }
    return {
      rejectNote,
      rejectInfo,
      rejectVariants,
      dialogBan,
      dialogReject,
      banNote,
      noteRules,
      validReject,
      validBan,
      formReject,
      formBan,
      productData,
      changeStatus,
    }
  }
}
</script>

<style scoped>
.v-list-item {
  min-height: 35px;
}
</style>