<template>
  <div>
    <account-role-title :role="$route.name.includes('seller') ? 'Store' : 'Admin'" />
    <!-- <breadcrumbs /> -->
    <span class="deals-details-title">{{ $t("Product Details") }}</span>
    <v-row>
      <v-col :md="isVerifiedHistory ? 9 : 12">
        <product-data-skeleton v-if="productLoading"></product-data-skeleton>
        <v-card class="mb-6" elevation="1" v-else :loading="productLoading" :disabled="productLoading">
          <v-row>
            <v-col cols="12" md="3" sm="6">
              <figure class="ml-3">
                <v-img width="100%" max-height="300" :src="productData.image" class="product-view-img"/>
              </figure>
            </v-col>
            <v-col cols="12" :md="isVerifiedHistory ? 6 : 7" sm="6">
              <v-list>
                <v-list-item>
                  <h3>{{ productData.title }}</h3>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title><b>{{ $t('Product ID:') }}</b> {{ productData.id }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title><b>{{ $t('ASIN:') }}</b>
                    <span class="primary--text">
                      <a
                        :href="productData.product_url"
                        target="_blank"
                      >
                        {{ productData.asin }}
                      </a>
                    </span>&nbsp;
                    <v-icon class="cursor-pointer mb-1" small color="primary" @click="copyText(productData.asin)">
                      {{ mdiClipboardMultipleOutline }}
                    </v-icon>
                    <v-icon class="cursor-pointer mb-1" small color="primary" @click="copyText(productData.product_url)">
                      {{ mdiLinkVariant }}
                    </v-icon>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title><b>{{ $t('Source:') }}</b>
                    <a
                      :href="productData.source_link"
                      target="_blank"
                    >
                      {{ productData.source_link }}
                    </a>&nbsp;
                    <v-icon class="cursor-pointer mb-1" small color="primary" @click="copyText(productData.source_link)">
                      {{ mdiClipboardMultipleOutline }}
                    </v-icon>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item two-line v-if="productData.vendor_note">
                  <v-list-item-content>
                    <v-list-item-title><b>{{ $t('Message for buyer:') }}</b></v-list-item-title>
                    <span class="pa-2" :class="$vuetify.theme.isDark ? 'vendor-note-dark' : 'vendor-note'">{{ productData.vendor_note }}</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" :md="isVerifiedHistory ? 3 : 2" sm="12">
              <admin-product-buttons v-if="($can('any product edit', 'all') || $can('product approve')) && $route.name == 'admin-product'"/>
              <v-list>
                <v-list-item v-if="productData.status < 4">
                  <v-btn block outlined color="primary" class="my-1" @click="clickDelete()">
                    {{ $t('Delete') }}
                  </v-btn>
                  <delete-product
                    :is-dialog-visible="deleteProductModal"
                    :id="productData.id"
                    :market-id="productData.market_id"
                    @productDeleted="$router.push({ name: $route.name == 'admin-product' ? 'admin-products-list' : 'seller-products', params: {tab: 'all'}})"
                  />
                </v-list-item>
                <v-list-item  v-if="productData.status < 4">
                  <v-btn block color="primary" class="my-1" @click="clickEdit()">{{ $t('Edit') }}</v-btn>
                  <edit-product
                    :id="productData.id"
                    :open="editProductModal"
                    @productEdited="getProduct()"
                  />
                </v-list-item>
                <v-list-item v-if="productData.status < 3 && !isAdminRoute">
                  <v-btn
                    block
                    :color="productData.status == 0 ? 'success' : 'secondary'"
                    class="my-1"
                    @click="changeStatus"
                  >
                    {{ productData.status == 0 ? $t('Publish') : $t('To Draft') }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
        <product-details-skeleton v-if="productLoading"></product-details-skeleton>
        <product-details v-else></product-details>
        <v-snackbar
          v-model="clipboardSnackbar"
          timeout="2000"
        >
          {{ $t('Copied to clipboard') }}
          <template v-slot:action="{ attrs }">
            <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="clipboardSnackbar = false"
            >
              {{ $t('Close') }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
      <v-col v-if="isVerifiedHistory" md="3" class="pl-0 pt-0">
        <AdminRejectedHistory />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {ref, computed, watch} from "@vue/composition-api";
import store from '@/store'
import axios from "@axios";
import router from "@/router";
import {mdiClipboardMultipleOutline, mdiLinkVariant} from "@mdi/js";
import DeleteProduct from "@/views/cabinet/product/DeleteProduct";
import EditProduct from "@/views/cabinet/product/EditProduct"
import ProductDetails from "@/views/cabinet/product/product-view/ProductDetails"
import AdminProductButtons from "@/views/cabinet/product/product-view/AdminProductButtons.vue"
import AdminRejectedHistory from "@/views/cabinet/product/product-view/AdminRejectedHistory.vue"
import ProductDataSkeleton from "@/views/market/components/ProductDataSkeleton";
import ProductDetailsSkeleton from "@/views/market/components/ProductDetailsSkeleton";
import {useRouter} from "@core/utils";

export default {
  name: "ProductView",
  components: {
    DeleteProduct,
    EditProduct,
    ProductDetails,
    AdminProductButtons,
    AdminRejectedHistory,
    ProductDataSkeleton,
    ProductDetailsSkeleton
  },
  setup() {
    const {route} = useRouter()
    const clipboardSnackbar = ref(false)
    const deleteProductModal = ref({})
    const editProductModal = ref({})
    const productData = computed(() => store.state.product.product)
    
    watch(route, () => getProduct())

    const clickDelete = () => {
      let num = deleteProductModal.value[0] !== undefined ? deleteProductModal.value[0] + 1 : 1
      deleteProductModal.value = {[productData.value.id]: num}
    }

    const clickEdit = () => {
      let num = editProductModal.value[0] !== undefined ? editProductModal.value[0] + 1 : 1
      editProductModal.value = {[productData.value.id]: num}
    }

    const copyText = (text) => {
      navigator.clipboard.writeText(text);
      clipboardSnackbar.value = true
    }

    const getProduct = () => {
      store.dispatch(`product/get${router.currentRoute.name == 'admin-product' ? 'Admin' : 'Seller'}Product`, {id: router.currentRoute.params.id})
    }

    const changeStatus = () => {
      store.dispatch('product/updateProductStatus', {
        id: productData.value.id,
        status: productData.value.status
      })
    }

    getProduct()

    return {
      changeStatus,
      isVerifiedHistory: computed(() => {
        if(store.state.product.product?.verification_history?.length) {
          return route.value.name.includes('admin')
        }
        return false
      }),
      isAdminRoute: computed(() => route.value.name == 'admin-product'),
      clipboardSnackbar,
      mdiClipboardMultipleOutline,
      mdiLinkVariant,
      copyText,
      productData,
      productLoading: computed(() => store.state.product.loading),
      deleteProductModal,
      editProductModal,
      clickDelete,
      clickEdit,
      getProduct
    }
  }
}
</script>
<style scoped>
.v-list-item {
  min-height: 35px;
}
</style>
