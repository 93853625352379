<template>
  <v-card elevation="1" :loading="productLoading" :disabled="productLoading">
    <v-row>
      <v-col cosl="12" md="7">
        <v-img 
          v-show="!productLoading"
          :src="productData.keepa_image || noKeepaImage"
          class="ml-3 mr-10"
        />
        <v-row>
          <v-col cols="12" sm="6">
            <v-list>
              <v-list-item two-line>
                <v-list-item-title>{{ $t('Marketplace:') }}</v-list-item-title>
                <v-list-item-subtitle><a :href="'https://'+productData.product_url_host" target="_blank">{{ productData.product_url_host }}</a></v-list-item-subtitle>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-title>{{ $t('Date:') }}</v-list-item-title>
                <v-list-item-subtitle>{{ productData.created_at }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-title>{{ $t('Sold Times:') }}</v-list-item-title>
                <v-list-item-subtitle>
                    {{ productData.sold_times }}/3
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" sm="6">
            <v-list>
              <v-list-item two-line>
                <v-list-item-title>{{ $t('Source:') }}</v-list-item-title>
                <v-list-item-subtitle><a :href="productData.source_link" target="_blank">{{ productData.source_link }}</a></v-list-item-subtitle>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-title>{{ $t('Store:') }}</v-list-item-title>
                <v-list-item-subtitle class="store-link">
                  <router-link class="mr-2" :to="{name: 'store-products', params: {slug: productData.market_slug}}">
                    {{ cutString(productData.market) }}
                  </router-link>
                  <rating :id-rating="productData.market_rating" />
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item two-line v-if="isAdminRoute">
                <v-list-item-title>{{ $t('Vendor:') }}</v-list-item-title>
                <v-list-item-subtitle class="store-link">
                  <router-link :to="{name: 'user', params: {id: productData.vendor_id}}">
                    {{ productData.vendor }}
                  </router-link>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-title>{{ $t('Status:') }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip 
                    small
                    :color="statusColor[status[productData.status]]"
                    :class="`${statusColor[status[productData.status]]}--text`"
                    class="v-chip-light-bg"
                  >
                    {{ status[productData.status] }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12">
          <v-list v-if="productData.description">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title><b>{{ $t('Description:') }}</b></v-list-item-title>
                <span>{{ productData.description }}</span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" class="pr-5">
        <v-list>
          <v-list-item>
            <Icons v-if="!productLoading"
              :marketplaceId="productData.marketplace_id"
              :bundle="!!productData.bundle"
              :isVariations="!!productData.has_variations"
              :isFragile="!!productData.is_fragile"
              :isWater="!!productData.is_meltable"
              :isBiohazard="!!productData.is_haz_mat"
              :isPackage="!!productData.is_oversize"
              :isAlert="!!productData.is_alert"
              :isAmazon="!!productData.is_amazon"
              :isAdult="!!productData.is_adult"
              :isSmallAndLight="!!productData.is_small_and_light"
              :restriction_data="productData.restriction_data"
              :restriction="true"
            />
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-row class="product-details-info">
          <v-col cols="7">
            <v-list>
              <v-tooltip v-if="productData.category" top color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item-title 
                    v-bind="attrs"
                    v-on="on"
                    class="ml-4 pb-2"
                  >
                    <b>{{ $t('Category:') }}</b>&nbsp;{{ productData.category }}
                  </v-list-item-title>
                </template>
                <span>{{ productData.category }}</span>
              </v-tooltip>
              <v-list-item-title v-else class="ml-4 pb-2">
                <b>{{ $t('Category:') }}</b>&nbsp;{{ productData.category }}
              </v-list-item-title>
              <div class="d-flex pb-2">
                <v-list-item-title class="ml-4" style="flex: inherit">
                  <b>{{ $t('30d avg BSR:') }}</b>&nbsp;
                </v-list-item-title>
                <v-chip
                  label
                  class="v-chip-light-bg flex-start"
                  :class="`${productData.bsr_avg30 > 0 ? 'success' : 'error'}--text`"
                  :color="`${productData.bsr_avg30 > 0 ? 'success' : 'error'}`"
                >
                  {{ productData.bsr_avg30 | numberWithSpaces  }}
                </v-chip>
              </div>
              <v-list-item-title class="ml-4 pb-2">
                <b>{{ $t('30d BSR Drops:') }}</b>&nbsp;{{ Number(productData.sales_rank_drops30) }}
              </v-list-item-title>
            </v-list>
          </v-col>
          <v-col cols="5">
            <v-list>
              <v-list-item-title class="ml-4 pb-2">
                <b>{{ $t('Size:') }}</b>&nbsp;{{ productData.size_type }}
              </v-list-item-title>
              <v-list-item class="responsive-fba-fbm">
                <v-row class="align-content-space-between" style="margin: 0">
                  <v-col class="pa-0">
                    <v-chip
                      label
                      color="info"
                      text-color="white"
                    >
                      FBM:{{ productData.fbm_offers || 0 }}
                    </v-chip>
                  </v-col>
                  <v-col class="pa-0" :class="{'mt-1': isVerifiedHistory}">
                    <v-chip
                      label
                      color="error"
                      text-color="white"
                    >
                      FBA:{{ productData.fba_offers || 0 }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-list>
          <v-list-item>
            <v-row>
              <v-col cols="4"><b>{{ $t('Price:') }}</b> {{ Number(productData.buy_box_price) }}</v-col>
              <v-col cols="4" class="text-center"><b>{{ $t('COG:') }}</b> {{ Number(productData.cog) }}</v-col>
              <v-col cols="4" class="text-right"><b>{{ $t('3PL:') }}</b> {{ Number(productData.third_pl) }}</v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="5">
            <v-list>
              <v-list-item two-line>
                <v-list-item-title><b>{{ $t('Profit:') }}</b></v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  <v-chip
                    label
                    class="v-chip-light-bg"
                    :class="`${productData.profit_fba > 0 ? 'success' : 'error'}--text`"
                    :color="`${productData.profit_fba > 0 ? 'success' : 'error'}`"
                  >
                    {{ Number(productData.profit_fba) }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-title><b>{{ $t('ROI:') }}</b></v-list-item-title>
                <v-list-item-subtitle class="text-right" style="padding-right: 10px">{{ Number(productData.roi_fba) }}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="2"></v-col>
          <v-col cols="5">
            <v-list>
              <v-list-item two-line>
                <v-list-item-title><b>{{ $t('Fees:') }}</b></v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ Number(productData.fees_total) }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-title><b>{{ 'R' + Number(productData.ref_fee_mod) * 100 + '%' }}</b></v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ Number(productData.ref_fee) }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-title><b>{{ $t('FBA fee:') }}</b></v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ Number(productData.fba_fee) }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-title><b>{{ $t('Sto:') }}</b></v-list-item-title>
                <v-list-item-subtitle class="text-right">{{ Number(productData.storage_fee) }}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-list>
          <v-list-item>
            <v-row class="mt-1">
              <v-col cols="8" class="text-right"><h2>{{ $t('Deal Price') }}</h2></v-col>
              <v-col cols="4" class="text-right"><h2>${{ Number(productData.price) }}</h2></v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row class="mb-1">
              <v-col cols="8" class="text-right"><h2>{{ $t('Marketplace Fee') }}</h2></v-col>
              <v-col cols="4" class="text-right"><h2>${{ Number(productData.deal_fee).toFixed(2) }}</h2></v-col>
            </v-row>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-row class="mt-2 mb-2">
              <v-col cols="8" class="text-right"><h2>{{ $t('Profit') }}</h2></v-col>
              <v-col cols="4" class="text-right"><h2>${{ (Number(productData.price) - Number(productData.deal_fee)).toFixed(2) }}</h2></v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import store from "@/store";
import {computed} from "@vue/composition-api";
import Icons from "./Icons"
import {useRouter} from "@core/utils";
import { numberWithSpaces, kitCut } from "@/plugins/helpers"
import Rating from "@/views/market/components/Rating";
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  name: "ProductDetails",
  components: {
    Icons,
    Rating
  },
  filters: {
    numberWithSpaces,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const {route} = useRouter()
    const cutString = (text, limit = menuIsVerticalNavMini.value ? 15 : 10) => {
      return kitCut(text, limit)
    }
    return {
      cutString,
      isVerifiedHistory: computed(() => {
        if(store.state.product.product?.verification_history?.length) {
          return route.value.name.includes('admin')
        }
        return false
      }),
      isAdminRoute: computed(() => route.value.name == 'admin-product'),
      productData: computed(() => store.state.product.product),
      productLoading: computed(() => store.state.product.loading),
      noKeepaImage: require('@/assets/images/app/pic_2.jpg'),
      status: {
        0: 'Draft',
        1: 'Unverified',
        2: 'Verified',
        3: 'Rejected',
        4: 'Banned',
      },
      statusColor: {
        /* eslint-disable key-spacing */
        Verified: 'success',
        Rejected: 'error',
        Unverified: 'warning',
        Draft: 'blue-grey',
        /* eslint-enable key-spacing */
      },
    }
  }
}
</script>
<style scoped>
.v-list-item {
  min-height: 35px;
}
</style>