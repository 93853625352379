<template>
  <v-card elevation="1" :disabled="productLoading" :loading="productLoading" class="pb-5 deals-order-history">
    <h3 class="pa-4">{{ $t('Rejected history') }}</h3>
    <v-timeline
        align-top
        dense
        class="py-0 pr-4"
    >
      <v-timeline-item
          v-for="history in productData.verification_history"
          :key="history.id"
          color="error"
          small
      >
        <div>
          <span><strong>{{ statusName[history.status] }}</strong></span></br>
          <span style="font-size: 12px">{{ history.date }}</span></br>
          <span style="font-size: 12px">{{ history.note }}</span>
        </div>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
import {computed} from "@vue/composition-api";
import store from "@/store";

export default {
  setup() {
    return {
        productData: computed(() => store.state.product.product),
        productLoading: computed(() => store.state.product.loading),
        statusName: {
            0: 'Rejected',
            1: 'Banned',
        }
    }
  }
}
</script>